@media (orientation: portrait) {
    #layer2bg {
        z-index: 5;
        bottom: 0%;
        height: 15%;
        position: fixed;
    }

    .layer2 {
        height: 100vh;
        width: 100vw;
    }

    .layer2bg {
        background-image: url("../images/layer2_bg.webp");
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;
        width: 100vw;
        z-index: 0;
        position: absolute;
    }
}

@media (orientation: landscape) {
    .layer2bg {
        background-image: url("../images/layer2_bg.webp");
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        z-index: 0;
        position: absolute;
    }
}



.background {
    background-image: url("../images/background.png");
    background-size: contain;
    background-repeat: repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
}

.homelink {
    position: fixed;
    top: 5%;
    left: 2%;
    margin: 2vh 2vw;
    font-size: 2em;
    color: white;
    text-decoration: none;
    text-shadow: 0.1em 0.1em 0.2em black;
    z-index: 10;
}


h1 {
    margin-bottom: 1em;
    font-weight: bold;
}

h2 {
    margin-top: 1em;
    margin-bottom: 1em;
}

#text {
    margin-top: 10em;
    font-family: 'nstc';
    color: 'black';
}

#text p {
    margin-top: 0.5em;
    font-family: 'nstc';
    color: 'black';
}

@media (orientation: portrait) {
    h1 {
        margin-bottom: 1em;
        font-weight: bold;
        color: black !important;
        font-size: 1.6rem;
        /* text-align: center; */
        letter-spacing: 0.1em;
        display: inline-block !important;
    }

    h2 {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .homelink {
        position: absolute;
        top: 5%;
        left: 2%;
        margin: 2vh 2vw;
        font-size: 1.5em;
        color: white;
        text-decoration: none;
        text-shadow: 0.1em 0.1em 0.2em black;
        z-index: 10;
    }

    #content {
        /* position: absolute; */
        margin-top: 10em;
        font-family: 'nstc';
        color: 'black';
    }

    #text {
        margin-top: 5em;
        font-family: 'nstc';
        color: 'black';
    }

    #text p {
        margin-top: 0.1em;
        font-family: 'nstc';
        color: 'black';
    }

    caption {
        font-size: 0.75em;
        text-align: center;
    }
}

caption {
    text-align: center;
}

.h-15 {
    height: 15%;
}

.invisible {
    visibility: hidden;
}