#scene div {
  text-align: center;
}

#planes {
  z-index: 1;
  width: 100%;
}

#smoke {
  z-index: 2;
  height: 100%;
}

#main-title {
  z-index: 3;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.page-container {
  width: 100%;
  height: 100%;
}

.scroll-btn {
  position: absolute;
  z-index: 10;
  padding-inline: 10px;
  cursor: pointer;
  border-radius: 20px;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 5px;
  box-shadow: inset 0 0 0 0 #d9d9d9;
}

.scroll-btn:hover,
.scroll-btn-text:hover {
  color: #ffffff;
  border-color: #ffffff;
  transition: all 0.3s ease;
}

.scroll-btn-text {
  margin-bottom: 0px;
  display: inline-block;
  letter-spacing: 0.1em;
  color: #d9d9d9;
  font-weight: 500;
  font-size: 2em;
  text-shadow: 0.1em 0.1em 0.2em black;
}

.background {
  background-image: url("../images/background.png");
  background-size: contain;
  background-repeat: repeat;
  height: 100%;
  width: 100%;
  scroll-snap-type: y proximity;
  overflow-x: hidden;
}

.image-card {
  /* transition: margin-left 0.3s ease; */
  transition: margin 0.3s ease-in-out;
}

.image-card:hover {
  /* margin-left: 10px; */
  margin-left: 5px;
  margin-top: -5px;
}

#section1,
#section4,
#section5,
#section6 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
  margin-bottom: 2vh
}

#section2,
#section3 {
  margin: auto;
  width: 96%;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
  text-align: center;
  margin-bottom: 2vh
}

@media (orientation: portrait) {
  .scroll-btn {
    position: absolute;
    z-index: 10;
    padding-inline: 10px;
    cursor: pointer;
    border-radius: 5px;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 2px;
    box-shadow: inset 0 0 0 0 #d9d9d9;
  }
  .scroll-btn-text {
    margin-bottom: 0px;
    display: inline-block;
    letter-spacing: 0.1em;
    color: #d9d9d9;
    font-weight: 500;
    font-size: 1.5em;
    text-shadow: 0.1em 0.1em 0.2em black;
  }
  
}