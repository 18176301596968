@media (orientation: portrait) {
    #audio {
        position: fixed;
        bottom: 1%;
        width: 80%;
        left: 10%;
        /* top: -1%; */
        /* margin: 2vh 2vw; */
        font-family: 'nstc';
        font-size: 1rem;
        z-index: 10;
    }
    #playerTitle {
        margin: 0 !important;
        padding: 0 !important;
    }
    #playerTitle p {
        color: rgb(74, 64, 36);
        font-weight: bold;
        margin: 0 !important;
        padding: 0 !important;
    }
    .nopadding {
        padding: 0 !important;
        margin: 0 !important;
    }
    .rhap_container{
        padding: 0 !important;
        margin: 0 !important;
    }
    .rhap_progress-container {
        padding: 0 !important;
        margin: 0 !important;
        margin-top: 5px !important;
        height: 5px !important;
    }
    .rhap_controls-section{
        padding: 0 !important;
        margin: 0 !important;
    }
    .rhap_progress-indicator {
        width: 10px !important;
        height: 10px !important;
        margin-left: -5px !important;
        top: -3px !important;
    }
    .rhap_main-controls-button .iconify {
            color: rgb(74, 64, 36) !important;
            width: 0.75 em;
            height: 0.75em;
    }
    .rhap_button-clear {
        margin: 0 !important;
    }
    /* .rhap_main-controls-button {
        width: 0.75em !important;
        height: 0.75em !important;
    } */
}

@media (orientation: landscape) {
    #audio {
        position: relative;
        top: 5%;
        right: -15%;
        /* margin: 2vh 2vw; */
        font-family: 'nstc';
        font-size: 1.5em;
        z-index: 10;
    }
}

.rhap_container {
    background-color: transparent !important;
    box-shadow: none !important;
}

.rhap_main-controls-button {
    color: rgb(74, 64, 36) !important;
} 

.rhap_progress-bar {
    background-color: rgba(74, 64, 36, 0.5) !important;
}

.rhap_progress-filled {
    background-color: rgb(74, 64, 36) !important;
}

.rhap_progress-indicator {
    background-color: rgb(74, 64, 36) !important;
}

.rhap_repeat-button {
    color: rgb(74, 64, 36) !important;
}

.rhap_volume-button {
    color: rgb(74, 64, 36) !important;
}

.rhap_volume-indicator {
    background-color: rgb(74, 64, 36) !important;
}

.rhap_volume-bar {
    background-color: rgba(74, 64, 36, 0.5) !important;
}

.rhap_volume-filled {
    background-color: rgb(74, 64, 36) !important;
}

.rhap_main-controls {
    flex: inherit !important;
}


@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}